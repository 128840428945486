import { HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpUtil {

  constructor() { }

  handleHttpError(error: any): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  getOptionsWithApp(appName: string, queryParams: HttpParams | null = null): {
    params?: HttpParams
  } {
    let queryParamsWithApp = new HttpParams()
    if (queryParams != null) {
      queryParams.keys().forEach(paramName => {
        const values = queryParams.getAll(paramName)
        if (values != null) {
          for (let value of values) {
            queryParamsWithApp = queryParamsWithApp.set(paramName, value)
          }
        }
      })
    }
    queryParamsWithApp = queryParamsWithApp.set('app', appName)
    return { params: queryParamsWithApp };
  }
}
